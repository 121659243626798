import React from 'react';
import styles from './getInTouch.module.css';

const GetInTouch = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.box1}>
          <h2 className={styles.heading}>Get In Touch</h2>
          <p className={styles.head}>Have Questions? Contact Us!</p>
        </div>
        <div className={styles.box2}>
          <form className={styles.form}>
            <div className={styles.formGroup}>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter your name*"
                required
              />
              <input
                type="email"
                className={styles.input}
                placeholder="Email id"
              />
            </div>
            <textarea
              className={styles.textarea}
              placeholder="Message"
            ></textarea>
              <div className={styles.formbutton}>
            <button type="submit" className={styles.submitButton}>
              Submit →
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;