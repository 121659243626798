import React, { useEffect, useState } from 'react';
import styles from './community.module.css';
import { Arow } from '../../components/Svg';

const Community = () => {
  const [isBox1Visible, setBox1Visible] = useState(true);
  const [isBox2Visible, setBox2Visible] = useState(true);



  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={`${styles.box1} ${isBox1Visible ? styles.visible : styles.hidden}`}>
          <h2 className={styles.heading}>Welcome to the  <br />world of Cube Collabs</h2>
          <p style={{textWrap:'wrap',lineHeight:1.3}} className={styles.text}>Empower your thought process, learn perspectives, use aiding tools and drive success</p>
          <button className={styles.button1}>Coming Soon <Arow /></button>
        </div>
        <div className={`${styles.box2} ${isBox2Visible ? styles.visible : styles.hidden}`}>
            
        
          <img src={require('../../assets/images/wellcome2background.png')} className={styles.mainImg} alt="photo" onContextMenu={(e) => e.preventDefault()}/>
          <img src={require('../../assets/images/tempImg.png')} className={styles.secondImage}  alt="photo"    onContextMenu={(e) => e.preventDefault()}/>
        </div>
      </div>
    </div>
  );
};

export default Community;
