import React from "react";
import styles from "./footer.module.css";
import { Iconq01, Iconq02, Iconq03, Iconq04, Iconq05, Youtube } from "../Svg";

const Footer = () => {

  const contactInfo = {
    email: "anirban@cubecollabs.com",
    countyCode: "+91",
    phone: "1234567890"
  }
  return (
    <div className={styles.container}>
      <div className={styles.contant}>
        <div className={styles.section1}>

          <img src={require("../../assets/images/logo.png")} alt="" style={{ width: 70, height: 50, marginTop: 15 }} onContextMenu={(e) => e.preventDefault()} />
          <div className={styles.pagess}>
            <a className={styles.pageLink}>
              Cube Collabs is an innovative platform built on the core pillars of connection, communication, and collaboration. It offers transformational tools designed to enhance professional efficiency and streamline workflows.
            </a>

          </div>
        </div>

        <div className={styles.sec}>
          <div className={styles.section} >
            <span className={styles.heading}>Contact Info </span>
            <div className={styles.pages}>
              <a
                href={`mailto:${contactInfo?.email}`}
                className={styles.pageLink}
              >
                <div>
                  <Iconq04 />
                </div>
                {contactInfo?.email}
              </a>


            </div>
          </div>

          <div className={styles.section} >
            <span className={styles.heading}>Address</span>
            <div className={styles.pages}>
              <span className={styles.pageLink}>
                Cube Collabs Business Solution (OPC) Pvt Ltd WorkFlo RankaJunction 224, 3rdFlr
                80/3 Old MADRS RD, Krishnarajapuram R S, Bangalore, Bangalore
                North, Karnataka, India, 560016
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.csection}>
        <div className={styles.icons}>
          <div>
            {" "}
            <a
              href="https://www.instagram.com/cubecollabs/?igsh=NzN6aHcxcjd0YzMw&utm_source=qr#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconq03 />
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/cubecollabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconq02 />
            </a>
          </div>
          <div>
            {" "}
            <a
              href="https://www.youtube.com/@cubecollabsshow"
              target="_blank"
              rel="noopener noreferrer"
            >

              <Youtube />
            </a>
          </div>


        </div>
        <div className={styles.isection}>
          <p className={styles.pageLink}>Privacy policy</p>
          <p className={styles.pageLink}>Terms of service</p>
        </div>
      </div>
      <hr className={styles.line}></hr>
      <p className={styles.pageLink} style={{ paddingBottom: 20 }}>© 2024 All rights reserved.</p>
    </div>
  );
};

export default Footer;
