import { Link, NavLink } from "react-router-dom";
import { Hamburger, Iconq02, Iconq03, Iconq04, Youtube } from "../Svg";
import { IoIosArrowDown } from "react-icons/io";
import React, { useEffect, useRef, useState } from "react"; // Ensure React imports are present
import styles from "./header.module.css"; // Assuming you have CSS for header styles

const Header = ({ connectUsRef, headerRef, scrollToSection, aboutCubeRef, joinInstagramRef, footerref }) => {
  const menuRef = useRef(null);
  const [sidebar, setSidebar] = useState(false);

  const handlenav = () => {
    setSidebar(!sidebar);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Scroll to the specific section using the id
  const handleScrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to the ConnectUs section
  const scrollToConnectUs = () => {
    if (connectUsRef && connectUsRef.current) {
      connectUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  const contactInfo = {
    email: "anirban@cubecollabs.com",
    countyCode: "+91",
    phone: "1234567890"
  }
  return (
    <div className={styles.container} ref={headerRef}>
      <div className={styles.mainHeader}>
        <div className={styles.hamburger}>
          <Hamburger handlenav={handlenav} />
        </div>
        <div className={styles.menuflex}>
          <a href="/">
            <img src={require("../../assets/images/logo.png")} alt="" onContextMenu={(e) => e.preventDefault()} />
          </a>
        </div>
        <div>
          <div
            ref={menuRef}
            className={
              sidebar ? `${styles.opened} ${styles.rightNav}` : styles.rightNav
            }
          >
            <NavLink className={styles.navmenu} onClick={handlenav} to="/">
              Home
            </NavLink>
            <NavLink
              className={styles.navmenu}
              onClick={() => scrollToSection(aboutCubeRef)}
            >
              Company
            </NavLink>
            <NavLink
              className={styles.navmenu}
              onClick={() => scrollToSection(joinInstagramRef)}

            >
              Stay updated
            </NavLink>
            <div className={styles.mobile} >

              <a href={`mailto:${contactInfo?.email}`}
                style={{ textDecoration: 'none', color: 'white', fontSize: 17, }}
              >

               
                <span>

                  {contactInfo?.email}
                </span>
              </a>

              <div style={{ display: 'flex', marginTop: 17, gap: 15 }}>


                <div>
                  <a
                    href="https://www.instagram.com/cubecollabs/?igsh=NzN6aHcxcjd0YzMw&utm_source=qr#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Iconq03 />
                  </a>
                </div>
                <div>
                  <a
                    href="https://twitter.com/cubecollabs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Iconq02 />
                  </a>
                </div>
                <div>
                  {" "}
                  <a
                    href="https://www.youtube.com/@cubecollabsshow"
                    target="_blank"
                    rel="noopener noreferrer"
                  >

                    <Youtube />
                  </a>
                </div>


              </div>
            </div>


          </div>
          {/* Scroll to ConnectUs section when this button is clicked */}
          <button
            onClick={(e) => handleScrollToSection(e, "from-cube-collab")}
            className={styles.connect}
          >
            Contact us →
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
