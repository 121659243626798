import React from 'react'
import styles from './customers.module.css'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

const Customers = () => {
  const data = [
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
    {
      url:"https://www.youtube.com/",
      image:require('../../assets/brands/image46.png')
    },
  ]
  return (
    <div className={styles.container}>

      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <div className={styles.slidetrack}>
           
           {
            data.map((item,index)=>(
              <a href={item?.url}>
              <div className={styles.box1}>
              <div className={styles.customer} key={index}>
                <img src={item?.image} className={styles.mainImg} style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
            </a>
            ))
           }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
