import React from 'react'
import styles from "./aboutCube.module.css"
const AboutCube = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.box1} style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>

          <img
            src={require("../../assets/images/about1.png")}
            alt=""
            className={styles.image}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className={styles.box2}>
          {/* <h2 className={styles.heading}>What is Cubecollabs?</h2> */}
          {/* <p className={styles.head}>Cubecollabs is an innovative platform built on the core pillars of connection, communication, and collaboration. It offers transformational tools designed to enhance professional efficiency and streamline workflows. By integrating these essential elements into a single, cohesive solution, Cubecollabs empowers individuals and teams to work smarter, stay connected, and achieve their goals more effectively.</p> */}
          <h2 className={styles.heading}>About The Company</h2>
          <p className={styles.head}>Cube Collabs Business Solution is dedicated to enhancing the ease of doing business for individuals and organizations alike. Our mission is to develop innovative tools that empower seamless connection, effective communication, and productive collaboration. By focusing on solutions that simplify complex workflows and foster meaningful interactions, we are committed to adding real value at both personal and enterprise levels.</p>


        
        </div>
      </div>

      <div className={styles.section1}>
        <div className={styles.box1}>
          <h2 className={styles.heading}>What is Cube Collabs?</h2>
          <p className={styles.head}>Cube Collabs is an innovative platform built on the core pillars of connection, communication, and collaboration. It offers transformational tools designed to enhance professional efficiency and streamline workflows. By integrating these essential elements into a single, cohesive solution, Cubecollabs empowers individuals and teams to work smarter, stay connected, and achieve their goals more effectively.</p>
        </div>
        <div className={styles.box1} style={{ display: 'flex', alignItems: 'center' ,justifyContent:'center'}}>
          <img
            src={require("../../assets/images/about2.png")}
            alt=""
            className={styles.image}
           
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

    </div>
    </div >
  )
}

export default AboutCube
