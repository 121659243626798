import React from 'react'
import styles from "./connectUs.module.css"
import { Facebook, Linkdin, YouTube } from '../../components/Svg'

import Customers from '../customers/Customers';

const ConnectUs = () => {

  return (
    <>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.box1}>
            <h3 className={styles.heading}>Connect with Us</h3>
            <div className={styles.icons1}>

            <a href="https://www.instagram.com/cubecollabs?igsh=NzN6aHcxcjd0YzMw&utm_source=qr" target="_blank" rel="noopener noreferrer">
                <img src={require('../../assets/images/instagram.png')} className={styles.mainImg} alt="instagram" style={{width:38,height:38}}/>
              </a>

              <a href="https://twitter.com/cubecollabs" target="_blank" rel="noopener noreferrer">
                <img src="./assets/images/Twitter123.svg" className={styles.mainImg} alt="Twitter" />
              </a>
             
              
              <a href="https://www.youtube.com/@cubecollabsshow" target="_blank" rel="noopener noreferrer">
                <YouTube />
              </a>
            </div>

          </div>
        </div>

      </div>
      <Customers />
    </>
  )
}

export default ConnectUs
